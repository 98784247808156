import "./map_component.css";
import L from "leaflet";
import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static targets = ["canvas"];
  static values = {
    geojson: Array,
    center: { type: Array, default: [48.8566, 2.3522] },
    format: String,
    zoom: { type: Number, default: 5 },
  };

  connect() {
    this.initializeMap();
    this.addTileLayer();
    this.addMarkers();
  }

  disconnect() {
    if (this.map) {
      this.map.remove();
    }
  }

  addTileLayer() {
    L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
      maxZoom: 19,
      attribution:
        '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    }).addTo(this.map);
  }

  initializeMap() {
    this.map = L.map(this.canvasTarget, {
      scrollWheelZoom: this.formatValue == "fullscreen",
    });
    this.map.fitBounds(this.getBounds());
  }

  getBounds() {
    const bounds = L.latLngBounds();
    this.geojsonValue.forEach((city) => {
      bounds.extend([city[0], city[1]]);
    });
    return bounds;
  }

  addMarkers() {
    this.geojsonValue.forEach((city) => {
      const marker = this.createMarker(city);
      this.addClickEvent(marker, city);
    });
  }

  createMarker(city) {
    return L.marker([city[0], city[1]]).addTo(this.map);
  }

  addClickEvent(marker, city) {
    marker.on("click", () => (window.location.href = city[2]));
  }
}
